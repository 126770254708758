import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import constants from '../../Constants';
import db from "../../utils/Utility";
import { Box, Button, TextField} from '@mui/material';

function createData(date, account, description, payIn, payOut ) {
  return { date, account, description, payIn, payOut  };
}

 
const SimpleTable = ({getList, generalInfo}) => {
    console.log(  getList )
    const [fullBalance, setFullBalance] =  React.useState(0)
    const [currency, setCurrency] = React.useState([]);
    const [selectedCurrencyId, setSelectedCurrencyId] = React.useState(0);
    const [fetchRows, setFetchRows] = React.useState([]);

    const info = generalInfo[0]
    console.log(info)
    let balance= 0;
    const rows = getList.map(item => ( 
        createData(item?.date, item?.account, item?.description,item?.payIn,item?.payOut)
      ));
 

    const calculateBalance = (row) => {        
        balance = (parseInt(row.payIn) -parseInt(row.payOut) + balance) 
        // console.log(balance)
        return balance
    }
    const getBalance = (filtList) =>{
      const totals = filtList.reduce((acc, transaction) => {
        acc.totalPayIn += transaction.payIn;
        acc.totalPayOut += transaction.payOut;
        return acc;
      }, { totalPayIn: 0, totalPayOut: 0 });
      return totals
    }
    async function fetchData() {
      try {
        const allCurrencies = await db.getAll('Currency');
      const updatedCurrencies = allCurrencies.map(cur => {
      // Filter the list to get items related to the current currency
      const cgetList = getList.filter((option) => option.account === cur.id);

      // Assuming getBalance(cgetList) returns an object with totalPayIn and totalPayOut
      const totBal = getBalance(cgetList);

      // Calculate the balance by subtracting totalPayOut from totalPayIn
      const getBal = parseInt(totBal.totalPayIn || 0) - parseInt(totBal.totalPayOut || 0);

      // Return the updated currency object with the new balance
      return {
        ...cur,           // Spread the existing properties of the currency object (cur)
        balance: getBal   // Add a new balance property
      };
    });


        // console.log(allCurrencies)
        setCurrency(updatedCurrencies)
      } catch (error) {
        console.error('Error fetching data:', error);        
      }
    }
    React.useEffect(() => {
      setFetchRows([])
    const cal = parseInt(balance) - parseInt(info.personDues);
    // console.log(parseInt(balance), parseInt(info.personDues));
    // console.log(cal);
    setSelectedCurrencyId(0)
    const sum = isNaN(cal) ? parseInt(balance) : cal; // Check if cal is NaN
    setFullBalance(sum);
    fetchData();
    }, [balance, getList]);

  const selAccount = (getRec) => {
    console.log(getRec.id)
    console.log(getRec.currency)
    setSelectedCurrencyId(getRec.id); // Update the selected currency ID
    const get = rows.filter((option) => option.account === getRec.id)
    setFetchRows(get)
    console.log(get)
        // fetchData()
  }
  return (
    <>
     <Card sx={{ minWidth: 275, maxWidth: '80%', margin: '0 auto' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {info?.Date}
            </Typography>
            <Typography component="div"  variant="h2" component="div" color="red">
              {info?.personName}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {info?.mobileNo}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {info?.personAdd}
            </Typography>

            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {currency?.map((row, index) => (
                <Button 
                  className={selectedCurrencyId === row.id ? 'selectedButton' : ''}
                  key={index} 
                  onClick={() => selAccount(row)} // Correctly pass the ID
                >
                  {row?.currency}
                  <br/>
                  {row?.balance}
                </Button>
              ))}
            </Typography>
{/*             
            <Typography variant="h2" component="div" color="red" style={{direction: 'ltr'}}> 
              {fullBalance}
            </Typography>
            <Typography variant="body2 " color="text.secondary">
              <br />
              زوړ حساب {info?.personDues}-  
            </Typography> */}
          </CardContent>
        </Card>
        <br/>
    <TableContainer component={Paper}>
      {fetchRows.length > 0 ? 
      <Table  size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{constants.getDate}</TableCell>
            <TableCell align="right">{constants.billInfo}</TableCell>
            <TableCell align="right">{constants.income}</TableCell>
            <TableCell align="right">{constants.outcome}</TableCell>
            <TableCell align="right">{constants.balance}</TableCell>
            {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchRows.map((row, ind) => (
            <TableRow
              key={ind}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="right">{row.description}</TableCell>
              <TableCell align="right">{row.payIn}</TableCell>
              <TableCell align="right">{row.payOut}</TableCell>
              <TableCell style={{direction: 'ltr'}} align="right">{calculateBalance(row)}</TableCell>
              {/* <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      : <p></p>}
    </TableContainer>
    </>
  );
}

export default SimpleTable;
